<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            <span> Syllabus </span>
            <v-spacer></v-spacer>
            <v-scroll-x-transition>
              <add-button
                permission="subject-create"
                @action="
                  (childFor = {}),
                    (childAdd = false),
                    (addEditParent = true),
                    newSyllabus()
                "
              >
                New Syllabus
              </add-button>
            </v-scroll-x-transition>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="pb-0">
              <v-flex>
                <v-grade-field v-model="gradeId"></v-grade-field>
              </v-flex>

              <v-flex>
                <v-subject-field
                  v-model="subjectId"
                  :grade-id="gradeId"
                ></v-subject-field>
              </v-flex>
              <search-button
                :disabled="!subjectId"
                style=""
                permission="subject-read"
                @action="get()"
              >
                Search
              </search-button>
            </v-card-title>
          </v-card>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            multi-sort
            class="elevation-0"
            item-key="id"
            :loading="loading"
            :expanded.sync="expanded"
            :single-expand="singleExpand"
          >
            <template v-slot:item="{ item, expand, isExpanded, index }">
              <tr>
                <td>{{ ++index }}</td>
                <td class="text-left">{{ item.chapter }}</td>
                <td class="text-left">
                  <view-more-or-less
                    :paragraph="item.description"
                  ></view-more-or-less>
                </td>
                <td class="text-right">
                  <v-btn
                    outlined
                    x-small
                    :color="'primary'"
                    @click="
                      (addEditParent = false),
                        newSyllabus(item.subject_id, item.id, item)
                    "
                  >
                    <v-icon left small>{{ "add_circle" }}</v-icon>
                    Add Chapter
                  </v-btn>
                  &nbsp;

                  <view-button
                    icon="dynamic_feed"
                    @action="
                      $router.push({
                        name: 'learning-materials',
                        params: {
                          gradeId: gradeId,
                          subjectId: subjectId,
                          syllabusId: item.id,
                        },
                      })
                    "
                    permission="subject-update"
                    v-if="
                      item.learning_materials &&
                      item.learning_materials.length > 0
                    "
                    :label="`${item.learning_materials.length} LM`"
                  />&nbsp;

                  <view-button
                    @action="expand(!isExpanded)"
                    permission="subject-update"
                    :disabled="
                      !item.chapters ||
                      (item.chapters && item.chapters.length < 1)
                    "
                    :label="
                      item.chapters.length > 1
                        ? item.chapters.length + ' Chapters'
                        : item.chapters.length + ' Chapter'
                    "
                  />
                  &nbsp;
                  <edit-button
                    permission="subject-update"
                    @agree="editData(item, true)"
                  />
                  <delete-button
                    permission="subject-delete"
                    @agree="form.delete(item.id)"
                  />
                </td>
              </tr>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td class="pa-0" :colspan="headers.length">
                <v-simple-table>
                  <template
                    :headers="headers"
                    :items="item.chapters"
                    v-slot:default
                  >
                    <tbody>
                      <tr
                        class="table_child"
                        v-for="(child, key) in item.chapters"
                        :key="key"
                      >
                        <td width="5%"></td>
                        <!--<td width="157" class="text-left">&nbsp;</td>-->
                        <td width="285" class="text-left">
                          {{ child.chapter }}
                        </td>
                        <td width="400" class="text-left">
                          <view-more-or-less
                            :limit="20"
                            :paragraph="child.description"
                          ></view-more-or-less>
                        </td>
                        <td class="text-right">
                          <edit-button
                            permission="subject-update"
                            @agree="editData(child)"
                          />
                          <delete-button
                            permission="subject-delete"
                            @agree="form.delete(child.id)"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>
          </v-data-table>

          <v-card-actions>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-dialog v-model="form.dialog" persistent max-width="600px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title"
              >Add/Update
              {{
                Object.keys(childFor).length ? "(" + childFor.chapter + ")" : ""
              }}</span
            >
          </v-card-title>
          <v-card-text class="pb-1 pt-4">
            <v-form
              ref="form"
              @submit.prevent="save"
              v-model="valid"
              :lazy-validation="lazy"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="save"
            >
              <v-container pa-0 grid-list-md>
                <v-layout row wrap>
                  <v-flex v-if="!childAdd" xs6>
                    <v-select
                      :loading="gradeLoading"
                      :items="grades"
                      class="pa-0"
                      label="Grade"
                      outlined
                      dense
                      v-model="grade"
                    />
                  </v-flex>
                  <v-flex v-if="!childAdd" xs6>
                    <v-select
                      :loading="subjectLoading"
                      :items="subjects"
                      class="pa-0"
                      label="Subject"
                      outlined
                      dense
                      v-model="form.subject_id"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      outlined
                      dense
                      autocomplete="off"
                      label="Chapter*"
                      required
                      class="pa-0"
                      v-model="form.chapter"
                      name="chapter"
                      :error-messages="form.errors.get('chapter')"
                      :rules="[(v) => !!v || 'Chapter is required']"
                    />
                  </v-flex>
                  <v-flex v-if="!addEditParent && !childAdd" xs12>
                    <v-select
                      :items="parents"
                      class="pa-0"
                      label="Parent"
                      outlined
                      dense
                      v-model="form.parent_id"
                    />
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      outlined
                      dense
                      type="number"
                      autocomplete="off"
                      label="Duration (in Hr)*"
                      required
                      class="pa-0"
                      v-model="form.duration"
                      name="code"
                      :error-messages="form.errors.get('duration')"
                      :rules="[(v) => !!v || 'Duration Hour is required']"
                    />
                  </v-flex>
                  <v-flex xs6>
                    <v-text-field
                      outlined
                      dense
                      type="number"
                      autocomplete="off"
                      label="Rank order*"
                      required
                      class="pa-0"
                      v-model="form.rank_order"
                      name="code"
                      :error-messages="form.errors.get('rank_order')"
                      :rules="[(v) => !!v || 'Rank order is required']"
                    />
                  </v-flex>

                  <v-flex xs12>
                    <v-textarea
                      name="description"
                      class="pa-0"
                      label="Description"
                      v-model="form.description"
                      outlined
                      dense
                      :rules="[(v) => !!v || 'Description is required']"
                    />
                  </v-flex>
                </v-layout>
              </v-container>
              <span v-if="form.errors.message"
                >{{ form.errors.message }}<br
              /></span>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              text
              @click="
                (addEditParent = false),
                  (form.dialog = false),
                  form.reset(),
                  $refs.form ? $refs.form.reset() : ''
              "
            >
              Close
            </v-btn>
            <v-btn color="success" text @click="validate()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";

export default {
  mixins: [Mixins],
  data: () => ({
    valid: true,
    lazy: false,
    loading: false,
    form: new Form(
      {
        chapter: "",
        description: "",
        subject_id: "",
        parent_id: "",
        duration: "",
        rank_order: "",
      },
      "/api/syllabus"
    ),
    search: null,
    headers: [
      { text: "#", align: "center", value: "id", width: "5%", sortable: false },
      // {text: 'Subject', align: 'left', value: 'subject_id', sortable: false, width: 150},
      {
        text: "Chapter",
        align: "left",
        value: "chapter",
        sortable: false,
        width: 250,
      },
      // {text: 'Parent', align: 'left', value: 'parent_id', sortable: false},
      {
        text: "Description",
        align: "left",
        value: "description",
        sortable: false,
        width: 300,
      },
      { text: "Action", align: "right", sortable: false, width: 350 },
    ],
    expanded: [],
    singleExpand: false,
    grades: [],
    sections: [],
    subjects: [],
    sectionLoading: false,
    gradeLoading: false,
    subjectLoading: false,
    grade: "",
    section: "",
    parents: [],
    gradeId: "",
    subjectId: "",
    childAdd: false,
    addEditParent: false,
    childFor: {},
  }),

  computed: {
    ...mapState(["batch"]),
  },

  watch: {
    pagination: function () {
      this.get();
    },
    "form.name": {
      handler(name) {
        this.form.name = this.capitalizeString(name);
      },
    },
    "form.dialog": function (value) {
      if (value) {
        this.form.batch_id = this.batch.id;
        this.form.grade_id = this.$route.params.gradeId;
      }
    },
    grade: function () {
      this.getSubjects();
    },
    batch: function (value) {
      this.getGrades();
    },
  },
  created() {
    // this.get();
  },

  mounted() {
    this.getGrades();
    if (this.$route.params.subjectId && this.$route.params.gradeId) {
      this.gradeId = parseInt(this.$route.params.gradeId);
      this.subjectId = parseInt(this.$route.params.subjectId);
    }
    this.get();
  },

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    clicked(value) {
      const index = this.expanded.indexOf(value.id);
      if (index === -1) {
        this.expanded.push(value.id);
      } else {
        this.expanded.splice(index, 1);
      }
    },
    get(params) {
      let extraParams =
        "subjectId=" + this.subjectId + "&gradeId=" + this.gradeId;
      if (
        [null, undefined].includes(this.batch.id) ||
        [null, undefined].includes(this.gradeId)
      )
        return;
      let query = [null, undefined].includes(params)
        ? this.queryString(extraParams)
        : params;
      if (this.subjectId) {
        this.loading = true;

        this.form
          .get(null, query)
          .then(({ data }) => {
            this.pagination.totalItems = data.meta.total;
            this.parents = data.data.map((res) => {
              return { value: res.id, text: res.chapter };
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    save() {
      this.form.grade_id = this.grade.id;
      this.form.batch_id = this.batch.id;
      this.form.exam = this.form.exam === true ? 1 : 0;
      this.form.store().then(() => {
        this.get();
      });
    },
    getGrades() {
      this.gradeLoading = true;
      this.$rest
        .get(
          "/api/grades?rowsPerPage=25&sortBy=rank&descending=false&filter=true&slim=true"
        )
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return { value: item.id, text: item.name, sections: item.sections };
          });
        })
        .finally(() => {
          this.gradeLoading = false;
        });
    },
    getSubjects() {
      if (this.grade) {
        this.$rest
          .get("/api/subject?grade=" + this.grade + "&batch=" + this.batch.id)
          .then(({ data }) => {
            this.subjects = data.data.map((item) => {
              return { value: item.id, text: item.name };
            });
          });
      }
    },
    newSyllabus(subjectId, parentId, childFor) {
      if (subjectId && parentId) {
        this.childAdd = true;
        this.form.subject_id = subjectId;
        this.form.parent_id = parentId;
        this.childFor = childFor;
      }

      this.form.dialog = true;
      this.$refs.form ? this.$refs.form.reset() : "";
      this.getGrades();
      this.grade = this.gradeId;
      this.form.subject_id = this.subjectId;
    },
    editData(item, isParent = false) {
      this.addEditParent = isParent;
      this.grade = this.gradeId;
      this.getSubjects();
      this.form.subject_id = item.subject_id;
      this.form.edit(item);
    },
    parent(id) {
      let parent = this.form.items.data.find((res) => res.parent_id === id);
      if (parent) {
        return parent.chapter;
      }
      return "-";
    },
  },
};
</script>
<style lang="scss" scoped>
.grade-select {
  .theme--light.v-chip {
    border-radius: 2px;

    &.primary {
      background-color: #0064c7;
      color: white;
    }
  }
}

table.table {
  margin: 0 auto;
  width: 98%;
  max-width: 98%;
}

.datatable-cell-wrapper {
  width: inherit;
  position: relative;
  z-index: 4;
  padding: 10px 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.datatable__expand-content .card__text {
  z-index: 3;
  position: relative;
}

.datatable-container {
  position: absolute;
  background-color: white;
  top: -50px;
  left: -14px;
  right: -14px;
  bottom: 0;
  z-index: 2;
  border: 1px solid #ccc;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.15), 0 1px 10px 0 rgba(0, 0, 0, 0.15),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.table_child {
  background-color: #eee;
}
</style>